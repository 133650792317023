import { render } from "./AnalysisViews.vue?vue&type=template&id=5fefe028"
import script from "./AnalysisViews.vue?vue&type=script&lang=ts"
export * from "./AnalysisViews.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5fefe028"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5fefe028', script)) {
    api.reload('5fefe028', script)
  }
  
  module.hot.accept("./AnalysisViews.vue?vue&type=template&id=5fefe028", () => {
    api.rerender('5fefe028', render)
  })

}

script.__file = "src/views/AnalysisViews.vue"

export default script